import React from 'react'
import store from './redux/store'
import { Provider } from 'react-redux'
import DataRetriever from './DataRetriever'
import type { CommonProps } from '../models/types'
import '../tailwind.css'

const ChatbotProvider = (props: CommonProps) => {
  return (
    <Provider store={store}>
      <DataRetriever {...props} />
    </Provider>
  )
}

export default ChatbotProvider
