import React, { useEffect, useState, useRef } from 'react'
import {
  createSpeechSynthesisPonyfill,
  SpeechSynthesisPonyfillType,
  SpeechSynthesisUtterance as Utterance
} from '@davi-ai/web-speech-cognitive-services-davi'
import type { PonyfillOptions } from '@davi-ai/web-speech-cognitive-services-davi'
import type { SpeechSynthesisCredentials } from '../models/types'
import { fetchSpeechServicesToken } from '../utils/fetchSpeechServicesToken'

interface SpeechSynthesisProviderProps {
  credentials: SpeechSynthesisCredentials
  setSpeaking: (value: boolean) => void
}

const SpeechSynthesisProvider = ({
  credentials,
  setSpeaking
}: SpeechSynthesisProviderProps): JSX.Element => {
  const [ponyfill, setPonyfill] = useState<SpeechSynthesisPonyfillType>()
  const ponyfillRef = useRef<SpeechSynthesisPonyfillType | undefined | null>(
    null
  )
  const voicesLoadedRef = useRef<boolean>(false)
  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null)

  /**
   * Async function to retrieve a new token and assign it to the speechConfig inside ponyfill.speechSynthesis
   * @param region string
   * @param key string
   */
  const refreshTokenInNineMinutes = async (
    region: string,
    key: string
  ): Promise<void> => {
    // A token is valid during 10 minutes, let's refresh it after 9 minutes
    refreshTimerRef.current = setTimeout(async () => {
      const token = await fetchSpeechServicesToken(region, key)

      if (token && ponyfill?.speechSynthesis?.speechConfig) {
        ponyfill.speechSynthesis.speechConfig.authorizationToken = token
        refreshTokenInNineMinutes(region, key)
      }
    }, 1000 * 60 * 9) // 9 minutes
  }

  const fetchTokenAndCreatePonyfill = async (
    key: string,
    region: string
  ): Promise<void> => {
    const token = await fetchSpeechServicesToken(region, key)

    if (token) {
      setPonyfill(
        createSpeechSynthesisPonyfill({
          credentials: {
            region: credentials.region,
            authorizationToken: token
          }
        })
      )
    }
  }

  /**
   * Launch token refresh after ponyfill creation if subscription key and region are given
   */
  useEffect(() => {
    ponyfill &&
      credentials?.subscriptionKey &&
      credentials?.region &&
      refreshTokenInNineMinutes(credentials.region, credentials.subscriptionKey)
  }, [ponyfill])

  useEffect(() => {
    if (credentials?.authorizationToken && credentials?.region) {
      const data: PonyfillOptions = {
        credentials: credentials
      }
      setPonyfill(createSpeechSynthesisPonyfill(data))
    } else if (credentials?.subscriptionKey && credentials?.region) {
      fetchTokenAndCreatePonyfill(
        credentials.subscriptionKey,
        credentials.region
      )
    }
  }, [credentials])

  const onVoicesChanged = (): void => {
    const voices = ponyfill?.speechSynthesis.getVoices()
    if (voices && Array.isArray(voices) && voices.length > 0) {
      voicesLoadedRef.current = true
    }
  }

  useEffect(() => {
    ponyfillRef.current = ponyfill
    if (ponyfill) {
      const voices = ponyfill.speechSynthesis.getVoices()
      if (voices && Array.isArray(voices) && voices.length > 0) {
        voicesLoadedRef.current = true
      } else {
        ponyfill.speechSynthesis.onvoiceschanged = onVoicesChanged
      }
    }
  }, [ponyfill])

  const handleSpeechStart = (): void => {
    setSpeaking(true)
    document.dispatchEvent(new Event('retorikSpiritEngineAudioStart'))
  }

  const handleSpeechend = (): void => {
    setSpeaking(false)
    document.dispatchEvent(new Event('retorikSpiritEngineAudioEnded'))
  }

  const processSpeechSynthesis = (e: CustomEvent): void => {
    if (ponyfillRef?.current && voicesLoadedRef?.current) {
      const text = e?.detail?.text
      if (text) {
        const utterance = new Utterance(text)
        utterance.onboundary = () => {}
        utterance.onviseme = () => {}
        utterance.onstart = handleSpeechStart
        utterance.onend = handleSpeechend
        utterance.onerror = handleSpeechend

        utterance.voice = ponyfillRef.current.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang === 'fr-FR')

        utterance.lang = 'fr-FR'
        ponyfillRef.current.speechSynthesis.speak(utterance, null)
      }
    }
  }

  useEffect(() => {
    document.addEventListener(
      'retorikSendSpeechSynthesis',
      processSpeechSynthesis
    )

    return () => {
      document.removeEventListener(
        'retorikSendSpeechSynthesis',
        processSpeechSynthesis
      )
    }
  }, [])

  return <React.Fragment />
}

export default SpeechSynthesisProvider
