import { configureStore } from '@reduxjs/toolkit'
import licenceSlice from './slices/licenceSlice'

const store = configureStore({
  reducer: {
    licenceReducer: licenceSlice
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
