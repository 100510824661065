import React from 'react'

type VideoProps = {
  srcMp4: string
  srcWebm: string
  onEnded: any
}

const Video = React.forwardRef<HTMLVideoElement, VideoProps>(
  ({ srcMp4, srcWebm, onEnded }, ref) => {
    return (
      <video
        ref={ref}
        className='invisible col-start-1 row-start-1 col-span-1 row-span-1 max-w-none self-start justify-self-center object-cover'
        muted
        preload='auto'
        onEnded={onEnded}
        playsInline
        controls={false}
      >
        <source src={srcMp4} type='video/mp4; codecs="hvc1"' />
        <source src={srcWebm} type='video/webm' />
      </video>
    )
  }
)

export default Video
