import React, { useEffect, useState } from 'react'
import Chatbot from './Chatbot'
import Loader from './Loader'
import type { CommonProps } from '../models/types'
import { useErrorCode } from './redux/hooks'
import SpeechSynthesisProvider from './SpeechSynthesisProvider'

const DataRetriever = ({
  agentData,
  height,
  size,
  speak,
  animationQueue,
  setAnimationQueue,
  speechSynthesisData
}: CommonProps): JSX.Element => {
  const allowedByLicence = true
  const errorCode = useErrorCode()
  const [speaking, setSpeaking] = useState<boolean>(speak)

  useEffect(() => {
    setSpeaking(speak)
  }, [speak])

  return (
    <React.Fragment>
      {allowedByLicence ? (
        <React.Fragment>
          <Chatbot
            agentData={agentData}
            height={height}
            size={size}
            speak={speaking}
            animationQueue={animationQueue}
            setAnimationQueue={setAnimationQueue}
          />
          {speechSynthesisData && (
            <SpeechSynthesisProvider
              credentials={speechSynthesisData}
              setSpeaking={setSpeaking}
            />
          )}
        </React.Fragment>
      ) : (
        <Loader errorCode={errorCode} />
      )}
    </React.Fragment>
  )
}

export default DataRetriever
