import ChatbotProvider from './components/ChatbotProvider'
import coreRenderSpiritEngineSprite from './renderSpiritEngine'
import sendMessage from './utils/audioManager'

const renderSpiritEngineSprite = coreRenderSpiritEngineSprite.bind(
  null,
  ChatbotProvider
)

export default ChatbotProvider

export { renderSpiritEngineSprite }

window.Retorik = {
  ...window.Retorik,
  sendMessage,
  renderSpiritEngineSprite
}
